import React from "react";
import './Hoodies.scss';
import hoodieGif from './Assets/featured hoodies/slowGif.gif';
import LogoText from './Assets/LogoTextCrop.png';
import MintButton from "./MintButton";
import Discord from './Assets/discordLogo.svg';
import Twitter from './Assets/twitterLogo.svg';
import Fade from 'react-reveal/Fade';



function Hoodie() {

    return (
        <div className="hoodiesContainer">
            <Fade>
            <p className="logoSub">METADRIP HOODIES</p>
            <div className="hoodieSectionContainer">
                {/*<img className="logoText" src={LogoText} alt=""/>*/}
                <p className="sideHeader">MORE THAN AN NFT</p>
                <div class="row">
                    <div class="column">
                        <img className="myGif" src={hoodieGif} />
                    </div>
                    <div class="column">
                        <div className="sideContainer">
                            <p className="sideText">
                                MetaDrip Hoodies represent a chance to own our exciting first  ever collection and become a member of an exclusive community.
                                This project is a set of <b>9,999 unique</b>, stylish and artistic hoodies each providing their wearers with multiple opportunities to benefit from ownership.
                                We have big plans for the Metaverse, so don't miss the chance to join the MetaDrip community.
                            </p>
                            {/*<a href="https://www.discord.com" target="_blank"><img className="socialsHoodies" src={Discord} alt="" /></a>*/}
                            <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="socialsHoodies" src={Twitter} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            </Fade>
        </div>
    );
}

export default Hoodie;