import React from "react";
import { useState } from "react";
import MintButton from "./MintButton";
import ConnectWalletButton from "./ConnectWalletButton";
import './Welcome.css';
import logo from './Assets/metaLogoWhite.png';
import h1 from './Assets/6.png';
import h2 from './Assets/17.png';
import './Countdown.scss';




function Countdown() {


  const [showMint, setShowMint] = useState(false);

  /*if (showMint === false) {
    var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();


    // Update the count down every 1 second
    var x = setInterval(function () {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (seconds < 10)
        seconds = "0" + seconds;

      if (minutes < 10)
        minutes = "0" + minutes;

      if (hours < 10)
        hours = "0" + hours;

      if (days < 10)
        days = "0" + days;

      // Display the result in the element with id="demo"
      document.getElementById("demo").innerHTML = days + " : " + hours + " : "
        + minutes + " : " + seconds + " ";

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setShowMint(true);
      }
    }, 1000);
  }*/

  return (
    <div className="countdownContainer">
      {/*id="demo" to a p tag to show countdown*/
        !showMint &&
        <p id="demo" className="countdownText">MINT ⋅ COMING ⋅ SOON</p>}
      {showMint && <MintButton />}
    </div>
  );
}

export default Countdown;

