import React from "react";
import "./Goals.scss";
import IMAGES from "./images";
import Fade from 'react-reveal/Fade';

const Goals = () => {

    return (
        <div className="GoalsContainer">
            <Fade>
                <p className="goalsHeader">GOALS</p>
            </Fade>
            <Fade>
                <p className="goalsDescription">Help us reach our selling goals to unlock community rewards</p>
            </Fade>
            <div className="timeline">
                <div className="column">
                    <Fade left>
                        <div className="show">
                            <div id="firstHeader" className="sellPercent">
                                <p className="goalText">25%</p>
                            </div>
                            <p id="firstHeaderText" className="goalTitle">
                                HOODIE GIVEAWAY
                            </p>
                            <p className="goalMessage">
                                We will give away 10 hoodies to our Discord group.  Each will come with full privileges that develop with the rest of the roadmap.
                            </p>
                        </div>
                    </Fade>
                    <div className="dontShow">
                    </div>
                    <Fade left delay={500}>
                        <div className="show">
                            <div id="secondHeader" className="sellPercent">
                                <p className="goalText">75%</p>
                            </div>
                            <p id="secondHeaderText" className="goalTitle">
                                METADRIP DAO
                            </p>
                            <p className="goalMessage">
                                A private members only club based on MetaDrip ownership. Includes exclusive benefits such as discounts/whitelists on all future projects.
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="column">
                    <Fade right delay={250}>
                        <div className="show">
                            <div id="thirdHeader" className="sellPercent">
                                <p className="goalText">50%</p>
                            </div>
                            <p id="thirdHeaderText" className="goalTitle">
                                $25,000 TO CHARITY
                            </p>
                            <p className="goalMessage">
                                We will give away 50 hoodies and make a charitable contribution of $25,000.
                            </p>
                        </div>
                    </Fade>
                    <div className="dontShow">
                    </div>
                    <Fade right delay={750}>
                        <div className="show">
                            <div id="fourthHeader" className="sellPercent">
                                <p className="goalText">100%</p>
                            </div>
                            <p id="fourthHeaderText" className="goalTitle">
                                METADRIP IN METAVERSE
                            </p>
                            <p className="goalMessage">
                                Launch a wearables collection based on MetaDrip hoodies in a metaverse such as DCL or Sandbox.
                            </p>
                        </div>
                    </Fade>
                </div>
            </div>
            <Fade bottom delay={1000}>
                <div id="finalGoal" className="show">
                    <div id="fifthHeader" className="sellPercent">
                        <p className="goalText">?</p>
                    </div>
                    <p id="fifthHeaderText" className="goalTitle">
                        WEARABLE API
                    </p>
                    <p className="goalMessage">
                        For the next phase, we have big plans to build a rich Web3 API ecosystem that game and metaverse makers can use to customize experiences and access based on your MetaDrip, allowing you to leverage your wearables across Metaverses!
                    </p>
                </div>
            </Fade>
        </div>
    );
}

export default Goals;
