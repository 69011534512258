import { useState } from "react";
import Web3 from "web3";
//import config from "./config";
//const abi = require(`./contract/${config.ABI}`);

export default function useWallet() {
	const [currentAccount, setCurrentAccount] = useState();
	const [web3, setWeb3] = useState();

	const connect = async () => {
		if (window.ethereum) {
			const accounts = await window.ethereum.request({
				method: "eth_requestAccounts",
			});
			setCurrentAccount(accounts[0]);
			setWeb3(new Web3(window.ethereum));
			return true;
		}
		return false;
	};

	const mint = async (quantity) => {
		try {
			const contract = new web3.eth.Contract(
				//abi,
				//config.CONTRACT_ADDRESS
				"123"
			);
			await contract.methods.mint(quantity).send({
				from: currentAccount,
				//value: quantity * config.PRICE,
				//gas: config.GAS_LIMIT,
				value: 1,
				gas: 1,
			});
		} catch (e) {
			console.log("Exception", e);
		}
	};
	return {
		connect,
		mint,
		web3
	};
}
