import React from "react";
import './Navbar.scss';
import { Link } from 'react-scroll';
import logo from './Assets/mdWhiteJustImg.png';
import Discord from './Assets/discordLogo.svg';
import Twitter from './Assets/twitterLogo.svg';
import Opensea from './Assets/openseaLogo.svg';
import ConnectWalletButton from "./ConnectWalletButton";

function Navbar() {

    return (
        <div className="Navbar">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <ul>
                <Link className="navLink">
                    <img className="logoTwo" src={logo} alt="wow"></img>
                </Link>
                <Link activeClass="active"
                    to="hoodiesContainer"
                    smooth={true}
                    offset={-50}
                    duration={500}
                    className="navLink">Hoodies</Link>
                <Link activeClass="active"
                    to="GoalsContainer"
                    smooth={true}
                    offset={250}
                    duration={500}
                    className="navLink">Goals</Link>
                <Link activeClass="active"
                    to="Team"
                    smooth={true}
                    offset={-75}
                    duration={500}
                    className="navLink">Team</Link>
                <Link activeClass="active"
                    to="FAQContainer"
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="navLink">FAQ</Link>
                <Link activeClass="active"
                    to="APISectionContainer"
                    smooth={true}
                    offset={50}
                    duration={500}
                    className="navLink">Wearable API</Link>
                <a href="https://www.opensea.io" target="_blank"><img className="socials" src={Opensea} alt="" /></a>
                {/*<a href="https://www.discord.com" target="_blank"><img className="socials" src={Discord} alt="" /></a>*/}
                <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="socials" src={Twitter} alt="" /></a>
                <div className="socials2">
                    <ConnectWalletButton />
                </div>
            </ul>

        </div>
    );
}

export default Navbar;
