import "./App.css";
import React from "react";
import useWallet from "./Wallet";
import MintButton from "./MintButton";
import ConnectWalletButton from "./ConnectWalletButton";
import global from "./global";
import Navbar from './Navbar.js';
import Welcome from './Welcome.js';
import AutoSlider from "./AutoSlider.js";
import Carousel from "./Carousel.js";
import Team from "./Team.js";
import FAQ from './FAQ.js';
import Hoodies from './Hoodies.js';
import Goals from "./Goals.js";
import Rarity from "./Rarity.js";
import Footer from "./Footer.js";
import APISection from "./APISection.js";
import Fade from 'react-reveal/Fade';

function App() {
	global.wallet = useWallet();

	return (
		<div className="App">
			<div className="topSection">
				<div className="navSection">
					<Navbar className="myNavbar" />
				</div>
				<Fade>
					<Welcome />
				</Fade>
			</div>
			<div>
				<div className="part1">
					<Fade bottom>
						<Carousel />
						<AutoSlider />
						<Carousel />
					</Fade>
				</div>
				<div className="hoodieSection">
					<Hoodies />
				</div>
				<Goals />
				<Fade duration={2000}>
					<APISection />
				</Fade>
				<div className="teamSection">
					<Team />
				</div>
				<div className="FAQSection">
					<Fade>
						<FAQ />
					</Fade>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default App;
