const IMAGES = {
    h1: require("./Assets/featured hoodies/8.png").default,
    h2: require("./Assets/featured hoodies/7.png").default,
    h3: require("./Assets/featured hoodies/16.png").default,
    h4: require("./Assets/featured hoodies/29.png").default,
    h5: require("./Assets/featured hoodies/57.png").default,
    h6: require("./Assets/featured hoodies/40.png").default,
    h7: require("./Assets/featured hoodies/20.png").default,
    h8: require("./Assets/featured hoodies/41.png").default
}

export default IMAGES;