import { React, useState } from "react";
import global from "./global";
import './ConnectWalletButton.scss';

const ConnectWalletButton = () => {
	console.log(global.wallet.web3);
	return (
		<div>
			<a className="connectButton"
				href="#"
				rel="noopener noreferrer"
				onClick={global.wallet.connect}
			>
				{global.wallet.web3 && <span>Connected</span> || <span>Connect</span> }
			</a>
		</div>
	);
};

export default ConnectWalletButton;
