import React from "react";
import MintButton from "./MintButton";
import ConnectWalletButton from "./ConnectWalletButton";
import './Welcome.css';
import logo from './Assets/metaLogoWhite.png';
import h1 from './Assets/6.png';
import h2 from './Assets/17.png';
import Countdown from './Countdown.js';


function Welcome() {

	return (
		<div className="Welcome">
			<img src={logo} alt="" className="mylogo" />
			
			<div className="botLeftContainer">
				
			</div>
			<div className="textContainer">
				<p className="welcomeMessage">The metaverse is coming and <b>wearables</b> will be a key part of your virtual life. MetaDrip Hoodies Minting goes live soon.
				</p>
				<Countdown className="countdownTimer" />
			</div>
		</div>
	);
}

export default Welcome;
