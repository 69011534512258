import "./Footer.scss";
import React from "react";
import logo from './Assets/mdWhiteJustImg.png';
import Discord from './Assets/discordLogo.svg';
import Twitter from './Assets/twitterLogo.svg';
import Opensea from './Assets/openseaLogo.svg';

function Footer() {

    return (
        <div className="footerContainer">
            <ul className="footerContent">
                <div className="leftFooter">
                    <li className="leftElement"><img className="footerImage" src={logo} alt="" /></li>
                </div>
                <div className="rightFooter">
                    <li className="rightElements"><a href="https://www.opensea.io" target="_blank"><img className="footerImage" src={Opensea} alt="" /></a></li>
                    {/*<li className="rightElements"><a href=""><img className="footerImage" src={Discord} alt="" /></a></li>*/}
                    <li className="rightElements"><a href="https://twitter.com/MetaDripClub" target="_blank"><img className="footerImage" src={Twitter} alt="" /></a></li>
                </div>
            </ul>
        </div>
    );
}

export default Footer;
