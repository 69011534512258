import React from "react";
import "./AutoSlider.scss";
import IMAGES from "./images";

const AutoSlider = () => {

    return (
        <div class="slider">
            <div class="slide-track">
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h1} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h2} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h3} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h4} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h5} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h6} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h7} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h8} height="100" width="250" alt="" />
                </div>

                <div class="slide">
                    <img className="slideImg" src={IMAGES.h1} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h2} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h3} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h4} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h5} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" className="slideImg" src={IMAGES.h6} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h7} height="100" width="250" alt="" />
                </div>
                <div class="slide">
                    <img className="slideImg" src={IMAGES.h8} height="100" width="250" alt="" />
                </div>
            </div>
        </div>
    );
}

export default AutoSlider;