import "./APISection.scss";
import React from "react";

function APISection() {


	return (
		<div className="APISectionContainer">
		</div>
	);
}

export default APISection;
