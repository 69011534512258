import { React } from "react";
import { useState } from "react";
import global from "./global";
import './MintButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const MintButton = () => {

	const [mintAmount, setMintAmount] = useState(1);

	function handleMintChange(mintingQuantity) {
		if (mintingQuantity >= 1 && mintingQuantity <= 5) {
			setMintAmount(mintingQuantity);
		}
	}


	return (
		<div className="mintContainer">
			<p className="mintQuantity">Mint Quantity: {mintAmount}</p>
			<input id="mySlider" className="mintSlider" onChange={(event) => handleMintChange(event.target.value)} type="range" min="1" max="5" step="1" list="steplist" defaultValue={"0"} />
			<datalist id="steplist">
				<option>1</option>
				<option>2</option>
				<option>3</option>
				<option>4</option>
				<option>5</option>
			</datalist>
			<a className="mintButton" href="#" rel="noopener noreferrer" onClick={global.wallet.mint}>
				{" "}
				Mint{" "}
			</a>
		</div>
	);
};

export default MintButton;
