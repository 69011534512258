import React from "react";
import "./Carousel.scss";
import IMAGES from "./images";

const Carousel = () => {

    return (
        <div class="slider1">
            <div class="slide-track1">
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h2} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h1} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h4} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h3} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h6} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h5} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h8} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h7} alt="" />
                </div>

                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h2} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h1} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h4} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h3} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h6} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h5} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h8} alt="" />
                </div>
                <div class="slide1">
                    <img className="slideImg" src={IMAGES.h7} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Carousel;