import React from "react";
import './Team.scss';
import Discord from './Assets/discordLogo.svg';
import Twitter from './Assets/twitterLogo.svg';
import { Link } from 'react-scroll';
import IMAGES from "./images";
import Fade from 'react-reveal/Fade';

function Team() {

    return (
        <div className="Team">
            <Fade>
                <div className="teamText">
                    <p className="teamSectionTitle">THE TEAM</p>
                    <p className="teamSectionDescription">Meet the people behind the scenes</p>
                </div>
            </Fade>
            <div class="box">
                <Fade bottom>
                    <div className="card">
                        <img className="avatar" src={IMAGES.h1} alt="" />
                        <p className="title" id="one">The Website Guy</p>
                        <p className="name">Jazzerboi</p>
                        <p className="description">Co-Founder of MetaDrip. Handles the UI/UX stuff.</p>
                        {/*<a href="https://www.discord.com" target="_blank"><img className="slinks" src={Discord} alt="" /></a>*/}
                        <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="slinks" src={Twitter} alt="" /></a>
                    </div>
                </Fade>
                <Fade bottom delay={500}>
                    <div className="card">
                        <img className="avatar" src={IMAGES.h2} alt="" />
                        <p className="title" id="two">The Crypto Guy</p>
                        <p className="name">WearableCoder</p>
                        <p className="description">Co-Founder of MetaDrip. Takes care of everything crypto.</p>
                        {/*<a href="https://www.discord.com" target="_blank"><img className="slinks" src={Discord} alt="" /></a>*/}
                        <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="slinks" src={Twitter} alt="" /></a>
                    </div>
                </Fade>
                <Fade bottom delay={1000}>
                    <div className="card">
                        <img className="avatar" src={IMAGES.h4} alt="" />
                        <p className="title" id="three">The Marketing Guy</p>
                        <p className="name">Christopher</p>
                        <p className="description">Boss of all the social media and marketing for MetaDrip.</p>
                        {/*<a href="https://www.discord.com" target="_blank"><img className="slinks" src={Discord} alt="" /></a>*/}
                        <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="slinks" src={Twitter} alt="" /></a>
                    </div>
                </Fade>
                <Fade bottom delay={1500}>
                    <div className="card">
                        <img className="avatar" src={IMAGES.h5} alt="" />
                        <p className="title" id="four">The Art Guy</p>
                        <p className="name">SushiLuvver</p>
                        <p className="description">Creator of the hoodies and overseer of art.
                        </p>
                        {/*<a href="https://www.discord.com" target="_blank"><img className="slinks" src={Discord} alt="" /></a>*/}
                        <a href="https://twitter.com/MetaDripClub" target="_blank"><img className="slinks" src={Twitter} alt="" /></a>
                    </div>
                </Fade>
            </div>
        </div>
    );
}

export default Team;
