import React from "react";
import './FAQ.scss';

function FAQ() {

    return (
        <div className="FAQContainer">
            <p className="FAQSectionTitle">FREQUENTLY ASKED QUESTIONS</p>
            <p className="FAQSectionSub">You've got questions, we've got answers.</p>
            <div class="row">
                <div class="col">
                    <div class="tabs">
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck1" />
                            <label class="tab-label" for="chck1"><p className="numeros">What is MetaDrip?</p></label>
                            <div class="tab-content">
								MetaDrip aims to be the premier MetaVerse first brand for wearables.  In addition to providing stunning in-world wardrobes and accessories, we want to build a rich Web3 API ecosystem that world and game makers can use to customize experiences and access based on your MetaDrip; allowing you to leverage your wearables across Metaverses!
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck2" />
                            <label class="tab-label" for="chck2"><p className="numeros">What are NFTs?</p></label>
                            <div class="tab-content">
                                Non-fungible tokens (NFTs) are digital collectibles on the blockchain that are easier to trade, store, and show off than physical versions. MetaDrip NFTs also indicate membership in our community at this exciting inception of the metaverse. The timing couldn't be better to own our very first collection!
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck3" />
                            <label class="tab-label" for="chck3"><p className="numeros">How do I mint a MetaDrip Hoodie?</p></label>
                            <div class="tab-content">
								Sign up for our whitelist to get in on our presale, or join the public sale. You'll need an ETH-compatible wallet like Metamask.  More instructions will come as we get closer to our sale date. 
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck5" />
                            <label class="tab-label" for="chck5"><p className="numeros">What is the mint price?</p></label>
                            <div class="tab-content">
                                0.1 ETH
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck6" />
                            <label class="tab-label" for="chck6"><p className="numeros">When is the sale?</p></label>
                            <div class="tab-content">
                               The mint date is still being decided. Join our Discord and Twitter communites to stay up to date and be among the first to know when a mint date is announced.
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck7" />
                            <label class="tab-label" for="chck7"><p className="numeros">How do I learn more and see some samples?</p></label>
                            <div class="tab-content">
                                Join our Discord and Twitter to get sneak peaks and updated information regarding the whitelist and sale.
                            </div>
                        </div>
                        <div class="tab">
                            <input className="faqInput" type="checkbox" id="chck8" />
                            <label class="tab-label" for="chck8"><p className="numeros">How many hoodies can I mint?</p></label>
                            <div class="tab-content">
                                You can mint up to 5 hoodies.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
